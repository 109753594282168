<template>
    <div class="container">
        <div class="export">
            <div class="input">
                <div>商品名称：</div>
                <el-input v-model="product_name" clearable placeholder="商品名称"></el-input>
            </div>
            <div class="input" style="margin-left:20px">
                <div>商品条码：</div>
                <el-input v-model="barcode" clearable placeholder="商品条码"></el-input>
            </div>
            <div class="brand">
                品牌：
                <el-select v-model="brand_id" clearable placeholder="请选择">
                    <el-option v-for="item in brandOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </div>

        </div>

        <div class="export" style="margin-top: 20px;">
            <!-- <div class="brand">
                库存状态：
                <el-select v-model="stockValue" clearable placeholder="请选择">
                    <el-option v-for="item in stockOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </div> -->
            <div class="brand">
                分类：
                <el-select clearable v-model="cate_id" placeholder="请选择">
                    <el-option v-for="item in typeOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </div>
            <div class="brand">
                库存筛选：
                <el-select v-model="stock_tag" clearable placeholder="请选择">
                    <el-option label="大于" value=">"></el-option>
                    <el-option label="小于" value="<"></el-option>
                    <el-option label="等于" value="="></el-option>
                    <el-option label="不等于" value="<>"></el-option>
                </el-select>
            </div>
            <div class="input" style="width:150px;margin-left: 10px;">
                <el-input v-model="stock" placeholder="输入数量"></el-input>
            </div>
            <div class="brand">
                效期：
                <el-date-picker v-model="expire_date" type="datetimerange" :default-time="['00:00:00', '23:59:59']"
                    range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
            </div>

        </div>
        <div class="export" style="margin-top: 20px;">
            <el-button type="primary" @click="getStockList('nopage')">查询</el-button>
            <el-button type="primary" @click="toStockBookList">预锁库存列表</el-button>
            <el-button type="info" @click="stockExport">导出</el-button>
        </div>


        <div class="table">
            <el-table :cell-style="{ textAlign: 'center' }" :header-cell-style="{ textAlign: 'center' }" border
                :data="stockList" @selection-change="handleSelectionChange" style="width: 100%">
                <!-- <el-table-column type="selection" width="60"></el-table-column> -->
                <el-table-column type="index" label="编号" width="60"></el-table-column>
                <el-table-column prop="product_name" label="商品名称"></el-table-column>
                <el-table-column prop="barcode" label="商品条码"></el-table-column>
                <el-table-column prop="spec_name" label="规格"></el-table-column>
                <el-table-column prop="spec_name" label="效期">
                    <template slot-scope="scope">
                        <span v-if="scope.row.hw_goods != ''">{{ scope.row.hw_goods.expire_date }}</span>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
                <el-table-column prop="share_canuse_stock" label="平台共享可用库存"></el-table-column>
                <el-table-column prop="shop_lock_stock" label="店铺锁定库存"></el-table-column>
                <!-- <el-table-column prop="shop_canuse_stock" label="店铺实际可用库存"></el-table-column> -->
                <el-table-column prop="shop_order_lock_stock" label="店铺订单占用库存"></el-table-column>
                <el-table-column prop="shop_lock_canuse_stock" label="店铺锁定可用库存"></el-table-column>
                <el-table-column label="操作" width="150">
                    <template slot-scope="scope">
                        <el-button @click="prelockstockAdd(scope.row)" type="text" size="small">库存预锁</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="pagination">
            <el-pagination background :hide-on-single-page="false" @current-change="curretnStockChange"
                @prev-click="curretnStockChange" @size-change="handleSizeChange" @next-click="curretnStockChange"
                :current-page="stockPage" :page-sizes="[10, 20, 30, 40]" layout="total,sizes,prev, pager, next,jumper"
                :total="stockTotal"></el-pagination>
        </div>
        <el-dialog title="锁定库存延期" :show-close="true" @close="diaClose" :close-on-press-escape="false"
            :close-on-click-modal="false" :visible.sync="dialogVisible" width="40%">
            <el-form :model="formInline" class="demo-form-inline">
                <el-form-item label="商品条码">
                    <el-input v-model="formInline.barcode" placeholder="商品条码"></el-input>
                </el-form-item>
                <el-form-item label="预锁数量">
                    <el-input v-model="formInline.prelock" placeholder="预锁数量"></el-input>
                </el-form-item>
                <el-form-item label="预锁天数">
                    <el-radio-group v-model="formInline.expire">
                        <el-radio :label="5">五天</el-radio>
                        <el-radio :label="10">十天</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-header">
                <el-button @click="stockBook" type="primary">锁定库存</el-button>
            </span>
        </el-dialog>
    </div>
</template>
  
<script>

import { formatDateTime, urlEncode, formatDateArray } from '@/utils/util.js'
import { BASE_URL, BASE_URL2 } from '../request/request.config';
export default {
    name: 'stock',
    data() {
        return {
            dialogVisible: false,
            product_name: '',
            barcode: '',
            limit: 10,
            updatePage: 1,
            BASE_URL2,
            stockPage: 1,
            detailInput: '',
            expire_date: [],
            stockOptions: [],
            brandOptions: [],
            typeOptions: [],
            cate_id: '',
            brand_id: '',
            stockTotal: 0,
            detailTotal: 0,
            detailLimit: 10,
            currentStockDetail: {},
            value1: [],
            stockList: [],
            stock_tag: '',
            stock: '',
            multipleSelection: [],
            formInline: {
                barcode: '',
                prelock: 1,
                expire: 5,
            },
            updateList: []
        }
    },
    computed: {
        shopInfo() {
            return JSON.parse(window.localStorage.getItem('shopInfo')) || {}
        }
    },
    created() {
        this.getStockList()
        this.getProductBrandList()
        this.getProductCateList()
        // this.getStockStatusList()
    },
    watch: {


    },
    methods: {
        getProductBrandList() { // 获取品牌列表

            this.$http2.post('/merapi/brand/list').then(res => {
                this.brandOptions = res.data.data.list
            }).catch(err => {
                this.$message.error(err + '')
            })
        },
        getProductCateList() { // 获取商品分类
            this.$http2.post('/merapi/category/list').then(res => {
                this.typeOptions = res.data.data.list
            }).catch(err => {
                this.$message.error(err + '')
            })
        },

        getStockStatusList() { // 获取库存状态
            this.$http2.post('/merapi/product/stock_status_list').then(res => {
                this.stockOptions = res.data.data
            }).catch(err => {
                this.$message.error(err + '')
            })
        },
        stockBook() {
            // 库存延期
            var data = this.formInline
            this.$http2
                .post('/merapi/prelockstock/add', data)
                .then(res => {
                    if (res.data.code == 0) {
                        this.$message.success(res.data.msg)
                        this.dialogVisible = false
                        this.getStockList()
                    } else {
                        this.$message.error(res.data.msg)
                    }

                })
                .catch(err => {
                    return this.$message.error(err)
                })

        },
        diaClose() {
            this.formInline = {
                barcode: '',
                prelock: 1,
                expire: 5,
            }
        },
        prelockstockAdd(data) {
            // 库存延期
            this.dialogVisible = true
            this.formInline.barcode = data.barcode
        },
        toStockBookList() {
            this.$router.push('/book')
        },
        handleSelectionChange(val) {
            // 多选值改变
            this.multipleSelection = val
        },

        curretnStockChange(e) {
            // 切换page
            this.stockPage = e
            this.getStockList()
        },
        handleSizeChange(e) {
            this.limit = e
            this.getStockList()
        },

        stockExport() {
            var product_name = this.product_name
            var barcode = this.barcode
            var stock_tag = this.stock_tag
            var cate_id = this.cate_id
            var brand_id = this.brand_id
            var stock = this.stock
            var expire_date = ''
            if (
                Object.prototype.toString.call(this.expire_date) === '[object Array]' &&
                this.expire_date[0]
            ) {
                // eslint-disable-next-line camelcase
                expire_date = formatDateArray(this.expire_date[0], this.expire_date[1])
            }
            var multipleSelection = this.multipleSelection
            // eslint-disable-next-line camelcase
            var ids = []
            if (multipleSelection.length > 0) {
                multipleSelection.forEach(ele => {
                    ids.push(ele.spec_id)
                })
            }
            // eslint-disable-next-line camelcase
            ids = ids.join(',')
            var token = window.localStorage.getItem('token')
            try {
                let params = {
                    ids, product_name, expire_date, barcode, token, stock_tag, stock, cate_id, brand_id

                }
                let data = urlEncode(params)
                window.location.href = BASE_URL2 + '/merapi/productstock/export?' + data
            } catch (e) {
                console.log(e)
                this.$message.error('服务器繁忙，请稍后重试')
            }
            // this.$http2
            //   .get('/merapi/stock/export',{ params:{ shop_id, ids, keyword, order_by }})
            //   .then(res => {
            //     this.$message.success(res.data.msg)
            //   })
            //   .catch(err => {
            //     return this.$message.error(err)
            //   })
        },
        getStockList(e) {
            // 获取库存列表

            var product_name = this.product_name
            var barcode = this.barcode
            var stock_tag = this.stock_tag
            var cate_id = this.cate_id
            var brand_id = this.brand_id
            var stock = this.stock
            this.stockPage = e === 'nopage' ? 1 : this.stockPage
            var expire_date = ''
            if (
                Object.prototype.toString.call(this.expire_date) === '[object Array]' &&
                this.expire_date[0]
            ) {
                // eslint-disable-next-line camelcase
                expire_date = formatDateArray(this.expire_date[0], this.expire_date[1])
            }
            var page = this.stockPage
            var limit = this.limit
            this.$http2
                .get('/merapi/productstock/list', { params: { stock_tag, expire_date, brand_id, cate_id, stock, product_name, barcode, page, limit } })
                .then(res => {
                    this.stockList = res.data.data.rows
                    this.stockTotal = res.data.data.total
                })
                .catch(err => {
                    return this.$message.error(err)
                })
        }
    }
}
</script>
  
<style scoped lang="less">
// .el-table/deep/.el-table__body tr:hover>td {
//   background-color: #eee!important;
// }
.red {
    color: red;
}

.el-table/deep/.el-table__body tr.bgcred>td {
    background-color: rgb(245, 108, 108) !important;
}

.brand {
    margin-left: 30px;
    font-size: 18px;
}

.export {
    display: flex;
    align-items: center;

    div {
        font-size: 16px;
        color: #222;
    }


    .el-button {
        background-color: #0078fb;
    }

    .date {
        font-size: 18px;
        margin-right: 20px;
    }

    .input {
        display: flex;
        align-items: center;

        div {
            width: 100px;
            font-size: 16px;
        }

        .el-input {
            width: 300px;
            font-size: 14px;
        }
    }

    .el-button {
        margin-left: 40px;
    }

    .msg {
        margin-left: 30px;
        font-size: 13px;
        color: rgb(102, 177, 255);
    }
}

.el-table /deep/ .bgcred {
    background-color: rgb(245, 108, 108);
    color: #fff;

}

.table {
    margin-top: 30px;
}

.logistics {
    margin-left: 20px;
    font-size: 18px;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
    align-items: center;
}</style>
  